import { Box, Center } from "@chakra-ui/react";
import Image from "next/image";
import { z } from 'zod';

export const ThumbnailProps = z.object({
  ext_file: z.string().nullish(),
  thumbnail: z.string().nullish(),
  comp_file: z.string().nullish(),
  url: z.string().nullish()
});

const Thumbnail = (props: z.infer<typeof ThumbnailProps>) => {
  const validationResult = ThumbnailProps.safeParse(props);
  // console.log(validationResult)
  

  return (
    <Center>
      <Box
        borderRadius="md"
        maxWidth={350}
        minWidth={260}
        minHeight={260}
        overflow="hidden"
      >
        <Image
          src={props.comp_file ?? props.ext_file ?? props.url ?? ""}
          alt="Thumbnail"
          height={380}
          width={380}
        />
      </Box>
    </Center>
  );
};

export default Thumbnail;