import {
  Center,
  Container,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import AudioPlayer from "./AudioPlayer";
import ShareButton from "@/components/ShareButton";
import GiftCheckOutWrapper from "@/components/stripe/GiftCheckoutWrapper";
import Thumbnail from "@/components/Thumbnails";
import { useAppSelector } from "@/hooks";
import { FaRegHeart } from "react-icons/fa";

function PlayerBar() {
  const { colorMode } = useColorMode();
  const card = useAppSelector((state) => state.nowplaying.card);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const backgroundColor = colorMode === "light" ? "white" : "dark.100";

  return (
    <div
      className="footer"
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: colorMode === "dark" ? "#252525" : "#f1f1f1",
      }}
    >
      <Container maxW={900} maxH={40}>
        {card.song.file && card.image && (
          <Grid
            templateColumns="repeat(3, 1fr)"
            gap={6}
            alignItems="center"
            ml={10}
            mr={10}
          >
            <Center mt={8} mb={8}>
              <FaRegHeart onClick={onOpen} size={18} />
            </Center>
            <AudioPlayer
              file={card.song.file}
              uuid={card.uuid}
              title={card.title}
              image={card.image}
            />
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              motionPreset="slideInBottom"
            >
              <ModalOverlay
                backdropInvert="80%"
                backdropFilter="blur(10px) hue-rotate(10deg)"
              />

              <ModalContent backgroundColor={backgroundColor} maxW={300}>
                <Text ml={6} mt={4}>
                  You are listening to...
                </Text>
                <ModalHeader>{card.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Thumbnail
                    ext_file={card.ext_file}
                    url={card.image}
                    thumbnail=""
                    comp_file={card.comp_file}
                  />
                  <Text fontSize="2xl" mt={5} mb={4}>
                    ${card.price}
                  </Text>

                  <GiftCheckOutWrapper
                    price={card.price}
                    art={""}
                    uuid={card.uuid}
                    image={card.image}
                    id={card.id}
                    issued={""}
                    ext_file={card.ext_file}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
            <Center>
              <ShareButton uuid={card.uuid} />
            </Center>
          </Grid>
        )}
        {card.song.file && card.ext_file && (
          <Grid
            templateColumns="repeat(3, 1fr)"
            gap={6}
            alignItems="center"
            ml={10}
            mr={10}
          >
            <Center mt={8} mb={8}>
              <FaRegHeart onClick={onOpen} size={18} />
            </Center>
            <AudioPlayer
              file={card.song.file}
              uuid={card.uuid}
              image={card.ext_file}
              title={card.title}
            />
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              motionPreset="slideInBottom"
            >
              <ModalOverlay
                backdropInvert="80%"
                backdropFilter="blur(10px) hue-rotate(10deg)"
              />

              <ModalContent backgroundColor={backgroundColor} maxW={300}>
                <Text ml={6} mt={4}>
                  You are listening to...
                </Text>
                <ModalHeader>{card.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Thumbnail
                    ext_file={card.ext_file}
                    url={card.image}
                    thumbnail=""
                    comp_file={card.comp_file}
                  />
                  <Text fontSize="2xl" mt={5} mb={4}>
                    ${card.price}
                  </Text>

                  <GiftCheckOutWrapper
                    price={card.price}
                    art={""}
                    uuid={card.uuid}
                    image={card.image}
                    id={card.id}
                    issued={""}
                    ext_file={card.ext_file}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
            <Center>
              <ShareButton uuid={card.uuid} />
            </Center>
          </Grid>
        )}

        {card.song.ext_file && card.image && (
          <Grid
            templateColumns="repeat(3, 1fr)"
            gap={6}
            alignItems="center"
            ml={10}
            mr={10}
          >
            <Center mt={8} mb={8}>
              {/* <CiCreditCard1 onClick={onOpen} size={22} /> */}
              <FaRegHeart onClick={onOpen} size={18} />
            </Center>
            <AudioPlayer
              file={card.song.ext_file}
              uuid={card.uuid}
              image={card.image}
              title={card.title}
            />
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              motionPreset="slideInBottom"
            >
              <ModalOverlay
                backdropInvert="80%"
                backdropFilter="blur(10px) hue-rotate(10deg)"
              />

              <ModalContent backgroundColor={backgroundColor} maxW={300}>
                <Text ml={6} mt={4}>
                  You are listening to...
                </Text>
                <ModalHeader>{card.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Thumbnail
                    ext_file={card.ext_file}
                    url={card.image}
                    thumbnail=""
                    comp_file={card.comp_file}
                  />
                  <Text fontSize="2xl" mt={5} mb={4}>
                    ${card.price}
                  </Text>

                  <GiftCheckOutWrapper
                    price={card.price}
                    art={""}
                    uuid={card.uuid}
                    image={card.image}
                    id={card.id}
                    issued={""}
                    ext_file={card.ext_file}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
            <Center>
              <ShareButton uuid={card.uuid} />
            </Center>
          </Grid>
        )}

        {card.song.ext_file && card.ext_file && (
          <Grid
            templateColumns="repeat(3, 1fr)"
            gap={6}
            alignItems="center"
            ml={10}
            mr={10}
          >
            <Center mt={8} mb={8}>
              <FaRegHeart onClick={onOpen} size={18} />
            </Center>
            <AudioPlayer
              file={card.song.ext_file}
              uuid={card.uuid}
              image={card.ext_file}
              title={card.title}
            />
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              motionPreset="slideInBottom"
            >
              <ModalOverlay
                backdropInvert="80%"
                backdropFilter="blur(10px) hue-rotate(10deg)"
              />

              <ModalContent backgroundColor={backgroundColor} maxW={300}>
                <Text ml={6} mt={4}>
                  You are listening to...
                </Text>
                <ModalHeader>{card.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Thumbnail
                    ext_file={card.ext_file}
                    url={card.image}
                    thumbnail=""
                    comp_file={card.comp_file}
                  />
                  <Text fontSize="2xl" mt={5} mb={4}>
                    ${card.price}
                  </Text>

                  <GiftCheckOutWrapper
                    price={card.price}
                    art={""}
                    uuid={card.uuid}
                    image={card.image}
                    id={card.id}
                    issued={""}
                    ext_file={card.ext_file}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
            <Center>
              <ShareButton uuid={card.uuid} />
            </Center>
          </Grid>
        )}
        {card.song && !card.song.ext_file && !card.song.file && (
          <AudioPlayer file="" uuid="" image="" title="" />
        )}
      </Container>
    </div>
  );
}

export default PlayerBar;
