import CheckOut from "@/components/stripe/Checkout";
import { Button, Center, Checkbox, Input, Text } from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import React, { useState } from "react";
import GiftCheckout from "./GiftCheckout";
import { z } from 'zod';


const stripePromise = loadStripe("pk_live_Zrv1vRrNwW4VRRSQv11X4pD6");

export const GiftCheckOutWrapperProps = z.object({
  price: z.number().nullish(),
  art: z.string().nullish(),
  uuid: z.string().nullish(),
  image: z.string().nullish(),
  id: z.number().nullish(),
  issued: z.string().nullish(),
  ext_file: z.string().nullish()
});

function GiftCheckOutWrapper(props: z.infer<typeof GiftCheckOutWrapperProps>) {
  const [isGift, setIsGift] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  const handleGift = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(event);
    // console.log(event.target.checked)
    const check = event.target.checked;
    setIsGift(check);
    // console.log("is gift? " + check);
    setShowFields(true);
    // console.log(isGift)
    // return(check)
  };

  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    // const recmail = mail
    // console.log(mail);
    // const sname = name
    // console.log(name);
    setShowCheckout(true);
    setShowFields(false);
    setShowConfirm(true);
  };

  const handleGiftEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("email");
    setMail(event.target.value);
  };

  const handleGifter = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("name");
    setName(event.target.value);
  };

  return (
    <div style={{ marginBottom: 15 }}>
      <Checkbox
        colorScheme="purple"
        mt={0}
        mb={5}
        onChange={(event) => handleGift(event)}
      >
        Buying as a gift?
      </Checkbox>
      {isGift && (
        <>
          {/* <p>is gift</p> */}
          {showConfirm && (
            <Text mt={5} mb={5}>
              {name} is buying a Songcard for {mail}
            </Text>
          )}
          {showFields && (
            <>
              <Input
                onInput={handleGiftEmail}
                placeholder="Enter recipient's email address"
              />
              <Input
                mt={5}
                mb={5}
                onInput={handleGifter}
                placeholder="Your name"
              />
              <Center>
                <Button
                  colorScheme="purple"
                  mt={2}
                  mb={0}
                  variant="outline"
                  size="md"
                  onClick={(event) => handleConfirm(event)}
                >
                  Confirm
                </Button>
              </Center>
            </>
          )}
          {showCheckout && (
            <Elements stripe={stripePromise}>
              <GiftCheckout
                price={props.price}
                card_id={props.id}
                // link={props.file}
                // ext_file={props.ext_file}
                art={props.art}
                uuid={props.uuid}
                issued={props.issued}
                image={props.image}
                name={name}
                mail={mail}
                ext_file={props.ext_file}
                //   isGift={props.isGift}
                //   gname={props.gname}
                //   remail={props.remail}
              />
            </Elements>
          )}
        </>
      )}
      {!isGift && (
        <>
          {/* <p>is not gift</p> */}
          <Elements stripe={stripePromise}>
            {/* {showCheckout && ( */}
            <CheckOut
              price={props.price}
              card_id={props.id}
              // link={props.file}
              ext_file={props.ext_file}
              art={props.art}
              uuid={props.uuid}
              issued={props.issued}
              image={props.image}
              //   isGift={props.isGift}
              //   gname={props.gname}
              //   remail={props.remail}
            />
            {/* )} */}
          </Elements>
        </>
      )}
    </div>
  );
}

export default GiftCheckOutWrapper;
